import React, {useRef} from "react"
import MathJax from 'react-mathjax2'
import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"

const rel1 = "\vtext {drift(%)} = 0.60";
const rel2 = "b \cdot h \cdot t = 1500x1500x300 mm";
const rel3 = "L = 3500 mm";
const rel4 = "\vrho_{l}(%) = 1.69";
const rel5 = "\vrho_{t}(%) = 0.64";
const rel6 = "f_{c}' = 18, 38.1 MPa";
const rel7 = "f_{y} = 420 MPa";
const rel8 = "f_{yh} = 417 MPa";
const rel9 = "P/(f_{ce}' A_{g}) = 0.07, 0.15";
const rel10 = "M/VH = 2.3";
const rel11 = "\vtext {CFRP sheets 0.55mm thick,} \vepsilon_{rup}=0.015, f_{uf} = 3480MPa";
const rel12 = "\vtext {drift(%)} = 3.07";

const rel13 = "\vtext {drift(%)} = 0.5";
const rel14 = "b \cdot h \cdot t = 350x550x110 mm";
const rel15 = "L = 1400, 2800 mm";
const rel16 = "\vrho_{l}(%) = 1.05";
const rel17 = "\vrho_{t}(%) = 0.49";
const rel18 = "f_{c}' = 30.4 MPa";
const rel19 = "f_{y} = 435 MPa";
const rel20 = "f_{yh} = 435 MPa";
const rel21 = "P/(f_{ce}' A_{g}) = 0.2";
const rel22 = "M/VH = 2.8";
const rel23 = "\vtext {CFRP sheets 0.11mm thick,} \vepsilon_{rup}=0.0177, f_{uf} = 3972 MPa";
const rel24 = "\vtext {drift(%)} = 3.7";

const rel25 = "\vtext {drift(%)} = 1.2";
const rel26 = "b \cdot h \cdot t = 450x450x75 mm";
const rel27 = "L = 900, 1350 mm";
const rel28 = "\vrho_{l}(%) = 1.07, 1.76";
const rel29 = "\vrho_{t}(%) = 0.13, 0.25";
const rel30 = "f_{c}' = 33.3 MPa";
const rel31 = "f_{y} = 550 MPa";
const rel32 = "f_{yh} = 550 MPa";
const rel33 = "P/(f_{ce}' A_{g}) = 0.06 \pm 0.13";
const rel34 = "M/VH = 2.3";
const rel35 = "\vtext {CFRP sheets 0.7-0.231mm thick,} \vepsilon_{rup}=0.008-0.028, f_{uf} = 1700-3000 MPa";
const rel36 = "\vtext {drift(%)} = 3.6";
const rel37 = "\vtext {drift(%)} = 5.4";
const rel38 = "\vtext {drift(%)} = 1.2, \vtext {loss of bonding}";


const rel101 = "\vmu_{d} = 1.0";
const rel102 = "b \cdot h \cdot t = 1500x1500x300 mm";
const rel103 = "L = 3500 mm";
const rel104 = "\vrho_{l}(%) = 1.69";
const rel105 = "\vrho_{t}(%) = 0.64";
const rel106 = "f_{c}' = 18, 38.1 MPa";
const rel107 = "f_{y} = 420 MPa";
const rel108 = "f_{yh} = 417 MPa";
const rel109 = "P/(f_{ce}' A_{g}) = 0.07, 0.15";
const rel110 = "M/VH = 2.3";
const rel111 = "\vtext {CFRP sheets 0.55mm thick,} \vepsilon_{rup}=0.015, f_{uf} = 3480MPa";
const rel112 = "\vmu_{d} = 5.3";

const rel113 = "\vmu_{d} = 1.0";
const rel114 = "b \cdot h \cdot t = 350x550x110 mm";
const rel115 = "L = 1400, 2800 mm";
const rel116 = "\vrho_{l}(%) = 1.05";
const rel117 = "\vrho_{t}(%) = 0.49";
const rel118 = "f_{c}' = 30.4 MPa";
const rel119 = "f_{y} = 435 MPa";
const rel120 = "f_{yh} = 435 MPa";
const rel121 = "P/(f_{ce}' A_{g}) = 0.2";
const rel122 = "M/VH = 2.8";
const rel123 = "\vtext {CFRP sheets 0.11mm thick,} \vepsilon_{rup}=0.0177, f_{uf} = 3972 MPa";
const rel124 = "\vmu_{d} = 7.54";

const rel125 = "\vmu_{d} = 1.0";
const rel126 = "b \cdot h \cdot t = 450x450x75 mm";
const rel127 = "L = 900, 1350 mm";
const rel128 = "\vrho_{l}(%) = 1.07, 1.76";
const rel129 = "\vrho_{t}(%) = 0.13, 0.25";
const rel130 = "f_{c}' = 33.3 MPa";
const rel131 = "f_{y} = 550 MPa";
const rel132 = "f_{yh} = 550 MPa";
const rel133 = "P/(f_{ce}' A_{g}) = 0.06 \pm 0.13";
const rel134 = "M/VH = 2.3";
const rel135 = "\vtext {CFRP sheets 0.7-0.231mm thick,} \vepsilon_{rup}=0.008-0.028, f_{uf} = 1700-3000 MPa";
const rel137 = "\vmu_{d} = 8.0, \vtext {short specimens}";
const rel138 = "\vmu_{d} = 4.0, \vtext {tall specimens}";




const LSExperimental = () => {
  //javascript
  const title = 'Retrofitted Piers';
  const metatitle = 'Retrofitted Piers';
  const description = 'Meta Description for Retrofitted Piers';
  const metadescription = description;


  const scrollToRef = (ref) => window.scrollTo({
    left:0,
    top:ref.current.offsetTop,
    behavior: 'smooth'
  });
  
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Ref5 = useRef(null);
  const Ref6 = useRef(null);
  const Ref7 = useRef(null);
  const Ref8 = useRef(null);
  const Ref9 = useRef(null);
  const Ref10 = useRef(null);
  const Ref11 = useRef(null);
  const Ref12 = useRef(null);
  const Ref13 = useRef(null);
  const Ref14 = useRef(null);
  const Ref15 = useRef(null);
  const Ref16 = useRef(null);
  const Ref17 = useRef(null);
  const Ref18 = useRef(null);
  const Ref19 = useRef(null);
  const Ref20 = useRef(null);
  const Ref21 = useRef(null);
  const Ref22 = useRef(null);
  const Ref23 = useRef(null);
  const Ref24 = useRef(null);
  const Ref25 = useRef(null);
  const Ref26 = useRef(null);
  const Ref27 = useRef(null);
  const Ref28 = useRef(null);
  
  const executeScroll = (e, ref) => {
    e.preventDefault();
    scrollToRef(ref);
  }
  
  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
        <ul>
        <li>
            <a className="more" href="../cylindrical/">Cylindrical Piers</a>
          </li>
          <li>
            <a className="more" href="../rectangular/">Rectangular Piers</a>
          </li>
          <li>
            <a className="more actclass" href="../hollow-rectangular/">Hollow Rectangular Piers</a>
          </li>
          <li>
            <a className="more" href="../hollow-circular/">Hollow Circular Piers</a>
          </li>
        </ul>
      </nav>
      <div className="content">
        <section className="exp-section">
          <h1>Hollow Rectangular Piers</h1>
          <div className="content-table">
            <h2>Table 1: Hollow Rectangular Piers: Limit state thresholds in terms of drift</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Drift (%)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading Type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref1)}>[1]</button> Cheng, C. T., Mo, Y.L., Yeh, Y.K. (2005)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel1}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel2}</MathJax.Node><br /><MathJax.Node inline>{rel3}</MathJax.Node><br /><MathJax.Node inline>{rel4}</MathJax.Node><br /><MathJax.Node inline>{rel5}</MathJax.Node><br /><MathJax.Node inline>{rel6}</MathJax.Node><br /><MathJax.Node inline>{rel7}</MathJax.Node><br /><MathJax.Node inline>{rel8}</MathJax.Node><br /><MathJax.Node inline>{rel9}</MathJax.Node><br /><MathJax.Node inline>{rel10}</MathJax.Node><br /><MathJax.Node inline>{rel11}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel12}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Crushing of concrete at the plastic hinge region, buckling of long. bars</td>
                </tr>
                <tr className="even">
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref2)}>[2]</button> Han, Q., Wen, J., Du, X., Jia, J. (2014)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel13}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal bars</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel14}</MathJax.Node><br /><MathJax.Node inline>{rel15}</MathJax.Node><br /><MathJax.Node inline>{rel16}</MathJax.Node><br /><MathJax.Node inline>{rel17}</MathJax.Node><br /><MathJax.Node inline>{rel18}</MathJax.Node><br /><MathJax.Node inline>{rel19}</MathJax.Node><br /><MathJax.Node inline>{rel20}</MathJax.Node><br /><MathJax.Node inline>{rel21}</MathJax.Node><br /><MathJax.Node inline>{rel22}</MathJax.Node><br /><MathJax.Node inline>{rel23}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel24}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Slight concrete spalling and buckling of long. bars</td>
                </tr>
                <tr>
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref3)}>[3]</button> Pavese, A., Bolognini, D., Peloso, S. (2004)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel25}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal bars</td>
                  <td rowSpan={3}>Cyclic loading</td>
                  <td rowSpan={3}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel26}</MathJax.Node><br /><MathJax.Node inline>{rel27}</MathJax.Node><br /><MathJax.Node inline>{rel28}</MathJax.Node><br /><MathJax.Node inline>{rel29}</MathJax.Node><br /><MathJax.Node inline>{rel30}</MathJax.Node><br /><MathJax.Node inline>{rel31}</MathJax.Node><br /><MathJax.Node inline>{rel32}</MathJax.Node><br /><MathJax.Node inline>{rel33}</MathJax.Node><br /><MathJax.Node inline>{rel34}</MathJax.Node><br /><MathJax.Node inline>{rel35}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel36}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Spalling of concrete cover</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel37}</MathJax.Node><br /><MathJax.Node inline>{rel38}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Rupture of FRP sheets, long. bar buckling, loss of long. bars bonding, concrete crushing</td>
                </tr>
                
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref1}>Cheng, C. T., Mo, Y.L., Yeh, Y.K. (2005). Evaluation of As-Built, Retrofitted, and Repaired Shear-Critical Hollow Bridge Columns under Earthquake-Type Loading, Journal of Bridge Engineering, ASCE, vol.10, No. 5, pp 520-529, <a href="https://doi.org/10.1061/(ASCE)1084-0702(2005)10:5(520)">https://doi.org/10.1061/(ASCE)1084-0702(2005)10:5(520)</a></li>
              <li ref={Ref2}>Han, Q., Wen, J., Du, X., Jia, J. (2014). Experimental and numerical studies on seismic behavior of hollow bridge columns retrofitted with carbon fiber reinforced polymer, Journal of Reinforced Plastics and Composites, Vol. 33, No. 24, pp 2214-2227, <a href="https://doi.org/10.1177/0731684414557716">https://doi.org/10.1177/0731684414557716.</a></li>
              <li ref={Ref3}>Pavese, A., Bolognini, D., Peloso, S. (2004). FRP SEISMIC RETROFIT OF RC SQUARE HOLLOW SECTION BRIDGE PIERS, Journal of Earthquake Engineering, Vol. 8, Special Issue 1, pp 225-550.</li>
            </ol>
          </div>
        </section>
        <section className="exp-section">
          <h1>Hollow Rectangular Piers</h1>
          <div className="content-table">
            <h2>Table 2: Limit state thresholds in terms of displacement ductility</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Displacement ductility (μ<sub>d</sub>)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading Type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref4)}>[1]</button> Cheng, C. T., Mo, Y.L., Yeh, Y.K. (2005)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel101}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel102}</MathJax.Node><br /><MathJax.Node inline>{rel103}</MathJax.Node><br /><MathJax.Node inline>{rel104}</MathJax.Node><br /><MathJax.Node inline>{rel105}</MathJax.Node><br /><MathJax.Node inline>{rel106}</MathJax.Node><br /><MathJax.Node inline>{rel107}</MathJax.Node><br /><MathJax.Node inline>{rel108}</MathJax.Node><br /><MathJax.Node inline>{rel109}</MathJax.Node><br /><MathJax.Node inline>{rel110}</MathJax.Node><br /><MathJax.Node inline>{rel111}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel112}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Crushing of concrete at the plastic hinge region, buckling of long. bars</td>
                </tr>
                <tr className="even">
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref5)}>[2]</button> Han, Q., Wen, J., Du, X., Jia, J. (2014)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel113}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal bars</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel114}</MathJax.Node><br /><MathJax.Node inline>{rel115}</MathJax.Node><br /><MathJax.Node inline>{rel116}</MathJax.Node><br /><MathJax.Node inline>{rel117}</MathJax.Node><br /><MathJax.Node inline>{rel118}</MathJax.Node><br /><MathJax.Node inline>{rel119}</MathJax.Node><br /><MathJax.Node inline>{rel120}</MathJax.Node><br /><MathJax.Node inline>{rel121}</MathJax.Node><br /><MathJax.Node inline>{rel122}</MathJax.Node><br /><MathJax.Node inline>{rel123}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel124}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Slight concrete spalling and buckling of long. bars</td>
                </tr>
                <tr>
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref6)}>[3]</button> Pavese, A., Bolognini, D., Peloso, S. (2004)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel125}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal bars</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel126}</MathJax.Node><br /><MathJax.Node inline>{rel127}</MathJax.Node><br /><MathJax.Node inline>{rel128}</MathJax.Node><br /><MathJax.Node inline>{rel129}</MathJax.Node><br /><MathJax.Node inline>{rel130}</MathJax.Node><br /><MathJax.Node inline>{rel131}</MathJax.Node><br /><MathJax.Node inline>{rel132}</MathJax.Node><br /><MathJax.Node inline>{rel133}</MathJax.Node><br /><MathJax.Node inline>{rel134}</MathJax.Node><br /><MathJax.Node inline>{rel135}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel137}</MathJax.Node><br /><MathJax.Node inline>{rel138}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Rupture of FRP sheets, long. bar buckling, loss of long. bars bonding, concrete crushing</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref1}>Cheng, C. T., Mo, Y.L., Yeh, Y.K. (2005). Evaluation of As-Built, Retrofitted, and Repaired Shear-Critical Hollow Bridge Columns under Earthquake-Type Loading, Journal of Bridge Engineering, ASCE, vol.10, No. 5, pp 520-529, <a href="https://doi.org/10.1061/(ASCE)1084-0702(2005)10:5(520)">https://doi.org/10.1061/(ASCE)1084-0702(2005)10:5(520)</a></li>
              <li ref={Ref2}>Han, Q., Wen, J., Du, X., Jia, J. (2014). Experimental and numerical studies on seismic behavior of hollow bridge columns retrofitted with carbon fiber reinforced polymer, Journal of Reinforced Plastics and Composites, Vol. 33, No. 24, pp 2214-2227, <a href="https://doi.org/10.1177/0731684414557716">https://doi.org/10.1177/0731684414557716.</a></li>
              <li ref={Ref3}>Pavese, A., Bolognini, D., Peloso, S. (2004). FRP SEISMIC RETROFIT OF RC SQUARE HOLLOW SECTION BRIDGE PIERS, Journal of Earthquake Engineering, Vol. 8, Special Issue 1, pp 225-550.</li>
            </ol>
          </div>
        </section>
      </div>
      
    </Layout>
  )
}

export default LSExperimental
